import moment from 'moment';
import Base from '@/api/Base';
import DeviceMeasurements from '@/models/DeviceMeasurements';
import GetBatteryLog from '@/api/dtos/GetBatteryLog';
import DeviceMeasurementsData from '@/models/DeviceMeasurementsData';
import OperationType from '@/api/dtos/OperationType';
import Store from '../store';

export default class Battery extends Base {
  constructor() {
    super(process.env.VUE_APP_RESIDENCE_API_ROOT_URL);
    this.instance.interceptors.request.use((config) => {
      const token = Store.getters['auth/getRoleAccessToken'];
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  async GetBatteryControlLogsByDeviceIdAndDate(id: string, date: Date):
    Promise<DeviceMeasurements[]> {
    const measKeys = ['power'];

    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);

    const startDateString = moment(startDate).utc().toISOString();
    const endDateString = moment(endDate).utc().toISOString();

    const { data } = await this.instance.get<GetBatteryLog>(`/v1/batteries/${id}/logs/${startDateString}/${endDateString}`);

    const inputArray = data.measurements.map((measurement) => {
      const shiftedDate = new Date(measurement.key);
      shiftedDate.setHours(shiftedDate.getHours() - date.getTimezoneOffset() / 60);
      return {
        key: shiftedDate,
        value: measurement.operationType === OperationType.Idle ? 0 : measurement.value,
      };
    });
    let previousValue: number | string | null = null;
    const deviceMeasurements: DeviceMeasurementsData[] = [];

    inputArray.forEach((measurement) => {
      if (measurement.value == null && previousValue !== null) {
        deviceMeasurements.push({ key: moment(measurement.key).subtract(1, 'seconds').format('YYYYMMDDHHmmss'), value: previousValue });
      }
      deviceMeasurements.push({ key: moment(measurement.key).format('YYYYMMDDHHmmss'), value: measurement.value });
      previousValue = measurement.value;
    });
    deviceMeasurements.push({ key: moment().format('YYYYMMDDHHmmss'), value: previousValue });
    return measKeys.map((measKey) => {
      return {
        key: measKey,
        data: deviceMeasurements,
      };
    });
  }
}
